<div class="flex h-full min-h-0 flex-col gap-4">
  <div class="mx-4 flex">
    <mat-form-field appearance="fill" class="mat-form-field-no-hint flex-auto">
      <mat-label>{{ 'utils.search' | translate }}</mat-label>
      <input
        matInput
        type="text"
        ignoreAutocomplete
        [formControl]="search"
        #searchElement
        cdkTrapFocus
        [cdkTrapFocusAutoCapture]="true"
      />
    </mat-form-field>
  </div>
  <div cdkVirtualScrollingElement class="scroll-y h-[500px] min-h-0">
    <ng-container *ngIf="entries$suggestions | async as suggestions">
      <ng-container *ngIf="suggestions.entries as entries">
        <div class="mx-4 mb-4 flex flex-row items-center justify-start gap-2 px-2">
          <mat-icon svgIcon="logoIcon" class="logo-icon"></mat-icon>
          <div class="flex flex-col">
            <h4 class="my-0" translate>utils.suggestions</h4>
            <span class="mat-hint" translate>buzz.ki-suggestions</span>
          </div>
        </div>
        <div class="mx-4 mb-4 flex flex-row flex-wrap items-center justify-start gap-2">
          @for (entry of entries; let last = $last; track entry.id) {
            <mat-card
              class="mat-card-button !mat-bg mb-2 cursor-pointer !rounded !px-3 !py-2"
              (click)="submitProject({ project: entry, task: entry.task })"
              (contextmenu)="openContextMenu($event, projectMenuTrigger, { entry: entry })"
              [class.border-active]="entry.selected"
              matTooltip
              [matTooltipOptions]="{ delay: [600, 0] }"
              [matTooltipTemplate]="projectPickerTooltip"
              [matTooltipTemplateContext]="{
                project: entry | projectProxy,
                tags: entry.tags,
              }"
            >
              <mat-card-content class="flex">
                <div class="truncate-flex flex flex-auto flex-col">
                  <div class="flex flex-row items-center justify-start gap-2">
                    <div
                      class="project-color-dot self-center"
                      *ngIf="entry.color as color"
                      [style.backgroundColor]="color"
                      [matTooltip]="'project.color' | translate"
                    ></div>
                    <h3 class="mb-0 truncate text-base font-bold">
                      {{ entry | defaultEntityTranslate: 'project' : 'project.none' | truncate: 80 }}
                    </h3>
                    <div class="mat-bg-30 h-1 w-1 rounded-full"></div>
                    <span class="flex-shrink-0 truncate">{{
                      entry.task?.name || ('task.none' | translate) | truncate: 40
                    }}</span>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          }
        </div>
      </ng-container>
      <div class="mx-4 -mt-2 mb-4 flex items-center justify-end">
        <button
          mat-button
          color="primary"
          class="link-button"
          *ngIf="suggestions.showMore !== undefined"
          (click)="showMore.value = !suggestions.showMore"
        >
          {{ (showMore.value ? 'utils.show_less' : 'utils.show_more') | translate }}
        </button>
      </div>
    </ng-container>
    <div class="h-full overflow-y-auto">
      @for (entry of entries$filtered | async; track entry.id) {
        <div>
          <mat-card
            class="mat-card-button !mat-bg mx-4 mb-4 cursor-pointer"
            (click)="submitProject({ project: entry })"
            (contextmenu)="openContextMenu($event, projectMenuTrigger, { entry: entry })"
            [class.border-active]="entry.selected"
          >
            <mat-card-content class="flex">
              <div class="truncate-flex flex flex-auto flex-col">
                <div class="flex flex-row gap-2">
                  <div
                    class="project-color-dot self-center"
                    *ngIf="entry.color as color"
                    [style.backgroundColor]="color"
                    [matTooltip]="'project.color' | translate"
                  ></div>
                  <h3 class="mb-0 truncate text-base font-bold">
                    {{ entry | defaultEntityTranslate: 'project' : 'project.none' | truncate: 100 }}
                  </h3>
                  <div class="!ml-auto flex flex-row items-center justify-start gap-2">
                    <mat-icon class="bg-accent-2 rounded-circle !p-1 text-lg" color="accent" inline *ngIf="entry.pinned"
                      >grade</mat-icon
                    >
                    <mat-icon
                      class="bg-accent-2 rounded-circle flex-none !p-1 text-lg"
                      inline
                      color="accent"
                      *ngIf="entry.billable"
                      [matTooltip]="'Billable_Alt' | translate"
                      >attach_money</mat-icon
                    >
                    <mat-icon
                      class="bg-default-2 rounded-circle flex-none !p-1 text-lg"
                      inline
                      *ngIf="entry.private"
                      [matTooltip]="'project.private.state' | translate"
                      >lock</mat-icon
                    >
                    <app-estimation-statistics-graph
                      *ngIf="entry.estimation > 0"
                      [estimation]="entry.estimation || 0"
                      [recorded]="
                        entry.timesRecorded
                          ? (entry.timesRecorded.billable?.clamp(0, entry.timesRecorded.billable) || 0) +
                            (!entry.billable
                              ? entry.timesRecorded.notBillable?.clamp(0, entry.timesRecorded.notBillable) || 0
                              : 0)
                          : 0
                      "
                      [completed]="entry.completed"
                    ></app-estimation-statistics-graph>
                  </div>
                </div>
                <div class="mat-hint">
                  {{ entry.client | defaultEntityTranslate: 'client' : 'client.none' | truncate: 100 }}
                </div>
                <div
                  *ngIf="entry.description as desc"
                  class="mat-typography whitespace-pre-wrap"
                  [innerText]="desc | truncate: 200 | trim"
                ></div>
              </div>
              <div class="flex items-center justify-center self-start">
                <mat-icon>keyboard_arrow_right</mat-icon>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      }
    </div>
    <!-- <div class="pb-48"></div> -->
  </div>
</div>
<mat-menu #projectContextMenu>
  <ng-template matMenuContent let-entry="entry">
    <div class="flex flex-col space-y-2">
      <button mat-menu-item (click)="this.togglePinProject(entry.id)">
        <mat-icon inline>star</mat-icon> {{ (entry.pinned ? 'project.fav-disable' : 'project.fav-enable') | translate }}
      </button>
      <a
        mat-menu-item
        [routerLink]="['/settings/projects', entry.id]"
        [disabled]="entry.useAsDefault"
        (click.prevent)="openProject(entry.id)"
      >
        <mat-icon inline>folder</mat-icon> {{ 'project.open' | translate }}
      </a>
    </div>
  </ng-template>
</mat-menu>
<div
  #projectMenuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="projectContextMenu"
  style="visibility: hidden; position: fixed"
  [style.left]="projectMenuPosition.x"
  [style.top]="projectMenuPosition.y"
></div>
<ng-template #projectPickerTooltip let-project="project" let-tags="tags">
  <div class="my-2 flex flex-col space-y-2 px-2.5 wflex-[1_0_220px]">
    <div class="space-y-1">
      <h3 class="mat-hint text-sm italic" translate>client.single</h3>
      <div class="font-semibold">{{ project?.client | defaultEntityTranslate: 'client' : 'client.none' }}</div>
    </div>
    <div class="space-y-1">
      <h3 class="mat-hint text-sm italic" translate>project.single</h3>
      <div class="font-semibold">{{ project | defaultEntityTranslate: 'project' : 'project.none' }}</div>
    </div>
    <div class="space-y-1" *ngIf="project?.description as desc">
      <h3 class="mat-hint text-sm italic" translate>Description</h3>
      <div>{{ desc | truncate: 120 }}</div>
    </div>
    <ng-container *ngIf="tags && tags.length > 0">
      <div class="space-y-1">
        <h3 class="mat-hint text-sm italic" translate>tag.plural</h3>
        <div class="flex flex-wrap gap-2">
          <div
            class="mbg-card pointer-events-none h-6 cursor-default select-none truncate rounded-full px-2.5 text-base leading-6"
            *ngFor="let item of tags; trackBy: trackId"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
