<app-loader [isActive]="loading()" [isAfterViewInit]="false" [enableCircle]="true"></app-loader>
<h1 matDialogTitle>
  <div class="flex flex-row items-center justify-start space-x-2">
    <button
      mat-button
      class="mat-button-square !rounded-lg"
      (click)="prevStep()"
      [matTooltip]="'paginator.previousPage' | translate"
      [disabled]="loading() || stepIndex() < 1"
      tabindex="-1"
    >
      <mat-icon>chevron_left</mat-icon>
    </button>
    @switch (stepIndex()) {
      @case (0) {
        <div>{{ 'time.pick-project' | translate }}</div>
      }
      @case (1) {
        <div class="flex flex-col">
          <div>{{ 'time.pick-task' | translate }}</div>
          @if ((selected.value$ | async)?.project; as project) {
            <div class="flex items-center space-x-1">
              <div
                class="h-2 w-2 self-center rounded-full"
                *ngIf="project.color as color"
                [style.backgroundColor]="color"
                [matTooltip]="'project.color' | translate"
              ></div>
              <div class="text-muted text-sm">
                {{ project | defaultEntityTranslate: 'project' : 'project.none' }}
              </div>
            </div>
          }
        </div>
      }
    }
    <div class="flex-auto"></div>
    <button mat-icon-button matDialogClose tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</h1>
<mat-dialog-content class="!m-0 !px-0">
  <tg-plain-stepper [selectedIndex]="stepIndex()" (selectedIndexChange)="selectedIndexChange($event)">
    <cdk-step>
      <tg-project-list
        class="max-h-inherit"
        [selectedEntity]="selected.value$ | async"
        (entityChange)="onSelectProject($event)"
        [suggestProjectByName]="(selected.value$ | async)?.title"
      ></tg-project-list>
    </cdk-step>
    <cdk-step>
      <tg-task-list
        [selectedEntity]="selected.value$ | async"
        (selectedEntityChange)="onSelectProject($event, true)"
      ></tg-task-list>
    </cdk-step>
  </tg-plain-stepper>
</mat-dialog-content>
<mat-dialog-actions align="end" class="mx-8 !mb-0 flex items-center space-x-4">
  @switch (stepIndex()) {
    @case (0) {
      <button mat-button (click)="createProject()" [disabled]="!(canManageProject$ | async)">
        {{ 'project.create' | translate }}
      </button>
    }
    @case (1) {
      <button mat-button (click)="createTask()" [disabled]="!(canCreateTask$ | async)">
        {{ 'task.add' | translate }}
      </button>
    }
  }
</mat-dialog-actions>
