import { FocusMonitor } from '@angular/cdk/a11y';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { CdkStepper } from '@angular/cdk/stepper';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Router } from '@angular/router';
import { debounceTimeAfterFirst } from '@app/_helpers/debounceAfterTime';
import { createClientProjectSearch } from '@app/_helpers/search';
import { createRxValue, distinctUntilChangedJson } from '@app/_helpers/utils';
import { DefaultEntityTranslatePipe } from '@app/_pipes/default-entity-translate.pipe';
import { clientProjectDialogId } from '@app/shared/dialogs/client-project-picker-stepper-dialog/client-project-picker-stepper-dialog.component';
import { BehaviorSubject, combineLatest, defer } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { firstBy } from 'thenby';
import { Project, ProjectsQuery, ProjectsService, Task, UserService, UserSettingsQuery } from 'timeghost-api';

type SelectedEntity = {
  project: Project;
  task?: Task;
};
@Component({
  selector: 'tg-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
})
export class ProjectListComponent implements OnInit {
  search = new FormControl('');
  @ViewChild('searchElement', { static: true }) private searchElement: HTMLInputElement;
  focusSearch() {
    this.focusMonitor.focusVia(this.searchElement, this.viewElement.element.nativeElement);
  }
  private _selectedEntity = createRxValue<SelectedEntity>();
  public get selectedEntity(): SelectedEntity {
    return this._selectedEntity.value;
  }
  @Input()
  public set selectedEntity(v: SelectedEntity) {
    this._selectedEntity.next(v);
  }
  @Output('entityChange') selectedEntityChange = new EventEmitter<SelectedEntity>();
  readonly entries$ = this.projectsQuery.selectAll({ filterBy: (x) => !x?.completed && x.projectType !== 'template' });
  readonly entries$filtered = combineLatest([
    this.entries$.pipe(
      startWith(
        this.projectsQuery.getAll({
          filterBy: (x) => x.projectType !== 'template',
        }),
      ),
    ),
    this.search.valueChanges.pipe(startWith(''), debounceTime(300), distinctUntilChanged()),
    this.userSettingsQuery.select((x) => x.pinnedProjects).pipe(debounceTime(50)),
    this._selectedEntity.asObservable().pipe(startWith(this.selectedEntity), distinctUntilChanged()),
  ]).pipe(
    map(([entries, q, pinnedProjects, selected]: [Project[], string, string[], SelectedEntity]) => {
      if (!q?.length) return [entries, pinnedProjects, selected];
      createClientProjectSearch(entries, {
        translateKeys: {
          client: (entity) => this.translateName.transform(entity, 'client', 'client.none'),
          project: (entity) => this.translateName.transform(entity, 'project', 'project.none'),
        },
      })
        .search(q)
        .map((x) => x.project);
      return [
        createClientProjectSearch(entries, {
          translateKeys: {
            client: (entity) => this.translateName.transform(entity, 'client', 'client.none'),
            project: (entity) => this.translateName.transform(entity, 'project', 'project.none'),
          },
        })
          .search(q)
          .map((x) => x.project),
        pinnedProjects,
        selected,
      ];
    }),
    map(([entries, pinnedProjects, selected]: [Project[], string[], SelectedEntity]) => {
      return entries
        .map((x) => ({
          ...x,
          pinned: pinnedProjects.findIndex((p) => p === x.id) !== -1,
          selected: selected?.project && x.id === selected.project.id,
        }))
        .sort(
          // @ts-ignore
          firstBy((x) => x.selected, 'desc')
            // @ts-ignore
            .thenBy((x) => x.useAsDefault)
            // @ts-ignore
            .thenBy((a, b) => b.pinned - a.pinned)
            .thenBy((a: any, b: any) =>
              a.pinned && b.pinned
                ? pinnedProjects.findIndex((x) => x === a.id) - pinnedProjects.findIndex((x) => x === b.id)
                : 0,
            )
            .thenBy((x) => x.name, 'asc'),
        );
    }),
  );
  private _taskName = new BehaviorSubject<string>(null);
  readonly taskName$ = this._taskName.asObservable().pipe(distinctUntilChanged());
  get taskName() {
    return this._taskName.getValue();
  }
  @Input('suggestProjectByName')
  set taskName(val: string) {
    this._taskName.next(val);
  }
  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewport: CdkVirtualScrollViewport;
  readonly showMore = createRxValue(false);
  readonly entries$suggestions = combineLatest([
    this.taskName$.pipe(startWith(this.taskName), distinctUntilChanged(), debounceTimeAfterFirst(100)),
    this.selectedEntityChange.asObservable().pipe(startWith(this.selectedEntity)),
    this.showMore.value$,
  ]).pipe(
    filter(([taskName]) => taskName?.length >= 2),
    distinctUntilChangedJson(),
    switchMap(([taskName, selected, showMore]) =>
      defer(() => {
        return this.projectsService
          .findByTaskName(taskName)
          .then((entries) => entries?.uniqBy(({ project, task }) => `${project?.id}${task?.id}`))
          .then((entries) => {
            return entries
              ?.filter((item) => !!item)
              .map(({ project, task }) => {
                return {
                  ...project,
                  task,
                  selected: selected && selected.project?.id === project.id && selected.task?.id === task?.id,
                };
              });
          });
      }).pipe(
        map((x) => {
          return x.sort(firstBy('selected', 'desc').thenBy('name'));
        }),
        map((x) => {
          const maxItems = 3;
          return {
            entries: x?.length > 0 ? (showMore ? x : x.slice(0, maxItems)) : null,
            length: x?.length,
            showMore: x?.length > maxItems ? showMore : undefined,
          };
        }),
      ),
    ),
    map((x) => (x.entries?.length > 0 ? x : null)),
  );
  constructor(
    private projectsQuery: ProjectsQuery,
    private userSettingsQuery: UserSettingsQuery,
    private userService: UserService,
    private projectsService: ProjectsService,
    private stepper: CdkStepper,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private translateName: DefaultEntityTranslatePipe,
    private focusMonitor: FocusMonitor,
    private viewElement: ViewContainerRef,
    private dialogs: MatLegacyDialog,
  ) {}
  ngOnInit(): void {}
  trackId(i: number, { id }: { id: string }) {
    return id;
  }
  submitProject(entity?: SelectedEntity) {
    if (entity?.project && entity.project.billable === undefined)
      entity.project = this.projectsQuery.getEntity(entity.project.id) || entity.project;
    this.selectedEntity = entity;
    this.selectedEntityChange.emit(entity);
  }
  togglePinProject(id: string) {
    return (
      this.isProjectPinned(id) ? this.userService.removePinnedProject(id) : this.userService.addPinnedProject(id)
    ).toPromise();
  }
  isProjectPinned(id: string) {
    return this.userSettingsQuery.getValue().pinnedProjects?.findIndex((x) => x === id) >= 0;
  }
  projectMenuPosition = { x: '0px', y: '0px' };
  openContextMenu(event: MouseEvent, trigger: MatMenuTrigger, data: any) {
    event.stopPropagation(), event.preventDefault();
    this.projectMenuPosition.x = event.clientX + 'px';
    this.projectMenuPosition.y = event.clientY + 'px';
    trigger.menuData = data;
    trigger.menu.focusFirstItem('mouse');
    trigger.openMenu();
  }
  openProject(id: string) {
    return this.router
      .navigate(['/settings/projects', id])
      .then(() => this.dialogs.getDialogById(clientProjectDialogId)?.close());
  }
}
